import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Form, Modal, Button, message } from 'antd';
import jwt_decode from 'jwt-decode';
import { userLoaded } from '../../store/actions';
import { serverFetch } from '../../server';
import './auth.css';

const Auth = ({ ...props }) => {
    const d = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState(0);
    const { onClose } = props;

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            close();
        };
    }, []);

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            //signInForm.submit();
            console.log('onKeyDown', form);
        }
    };

    const close = () => {
        setLoading(false);
        form.resetFields();
    };

    const onRestore = () => setStage(2);

    const onSendCode = () => {
        const email = form.getFieldValue("email");
        if (!email) return;
        setLoading(true);
        serverFetch(`auth/GenerateCheckcode`, { method: 'POST', bodyData: { email }})
            .then(() => {
                setStage(stage === 0 ? 1 : 3);
                setLoading(false);
            })
            .catch(() => {
                message.error("Ошибка отправки кода");
                setLoading(false);
            });
    }

    const onByPassword = () => setStage(4);

    const onLogin = () => {
        const email = form.getFieldValue("email");
        const password = form.getFieldValue("password");
        const passwordConfirm = form.getFieldValue("passwordConfirm");
        const checkcode = form.getFieldValue("checkcode");
        if (stage === 3){
            if (password !== passwordConfirm) {
                message.error("Пароли не совпадают");
                return;
            }
            setLoading(true);
            const data = {email, password, checkcode};
            serverFetch(`auth/NewPassword`, { method: 'POST', bodyData: data })
                .then((data) => {
                    if (data) {
                        window.accessToken = data.accessToken;
                        localStorage.setItem('refreshToken', data.refreshToken);
                        var result = jwt_decode(data.accessToken);
                        let session = {
                            userId: result.userId,
                            touristName: result.touristName,
                            touristSurname: result.touristSurname,
                            touristPatronymic: result.touristPatronymic,
                            email: result.email,
                            touristPhone: result.touristPhone,
                        };
                        d(userLoaded(session));
                        onClose();
                    } else {
                        message.error('Ошибка авторизации');
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
        else {
            setLoading(true);
            const data = stage === 1 ? {email, checkcode} : {email, password};
            serverFetch(`auth/signin`, { method: 'POST', bodyData: data })
                .then((data) => {
                    if (data) {
                        window.accessToken = data.accessToken;
                        localStorage.setItem('refreshToken', data.refreshToken);
                        var result = jwt_decode(data.accessToken);
                        let session = {
                            userId: result.userId,
                            touristName: result.touristName,
                            touristSurname: result.touristSurname,
                            touristPatronymic: result.touristPatronymic,
                            email: result.email,
                            touristPhone: result.touristPhone,
                        };
                        d(userLoaded(session));
                        onClose();
                    } else {
                        message.error('Неверный пароль');
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    message.error('Неверный пароль');
                });
        }
    }
    let title = "";
    switch (stage) {
        case 0: case 2: title = "Введите почту"; break;
        case 1: title = "Введите код, отправленный на почту"; break;
        case 3: title = "Введите код и новый пароль с подтверждением"; break;
        case 4: title = "Введите почту и пароль"; break;
        default: title = ''; break;
    }

return <Modal
        forceRender
        open={true}
        showTitle={true}
        footer={null}
        onCancel={onClose}
        getContainer={false}
        title={<div className='auth-logo'>Вход или регистрация</div>}
        bodyStyle={{ background: '#F6F7F8', padding: 20, margin: '0 -20px -20px' }}
        width={400}
        >
        {/* <Form size={'large'} form={form} initialValues={{ remember: true }}> */}
        <Form size={'large'} form={form}>
            <div className='auth-label'>{title}</div>
            <Form.Item
                name='email'
                rules={[ { type: 'email', message: 'Неверный формат Email' }, { required: true, message: 'Пожалуйста введите Email' }, ]}
                hasFeedback
                style={{ marginBottom: 24 }}
            >
                <Input disabled={loading || stage === 1 || stage === 3} placeholder='Почта' />
            </Form.Item>
            {(stage === 0 || stage === 2) && <Form.Item><div className='auth-buttons-center'><Button onClick={onSendCode} disabled={loading}>Получить код</Button></div></Form.Item>}
            {(stage === 1 || stage === 3) && <Form.Item name="checkcode"><Input placeholder='Одноразовый код'/></Form.Item>}
            {(stage === 3 || stage === 4) && <Form.Item name='password' style={{ marginBottom: 24 }}><Input.Password placeholder={'Пароль'} disabled={loading} /></Form.Item>}
            {(stage === 3) && <Form.Item name='passwordConfirm' style={{ marginBottom: 24 }}><Input.Password placeholder={'Подтвердите пароль'} disabled={loading} /></Form.Item>}
            <Form.Item>
                <div className='auth-buttons'>
                    {stage === 0 && <div className='auth-restore' onClick={onByPassword}>Вход с паролем</div>}
                    {stage === 4 && <div className='auth-restore' onClick={onRestore}>Забыли пароль?</div>}
                    {(stage === 0 || stage === 1 ||stage === 3 ||stage === 4) && <Button type='primary' shape='round' onClick={onLogin} disabled={loading || stage === 0}>Войти</Button>}
                </div>
            </Form.Item>
        </Form>
    </Modal>
};

export default Auth;

// stage                      Почта       Получить     Ввод кода   Пароль   Войти       Подтверждение  Забыли  / Вход
//                                        код                                           пароля         пароль?   с паролем
// 0 - Авт. по электноке      Активно     Активно      Скрыто      Скрыто   Неактивно   Скрыто         Скрыто    Активно
// 1 - Код отправлен.         Неактивно   Скрыто       Активно     Скрыто   Активно     Скрыто         Скрыто    Скрыто
// 2 - Восстановление пароля  Активно     Активно      Скрыто      Скрыто   Скрыто      Скрыто         Скрыто    Скрыто
// 3 - Подтв. восст. пароля   Неактивно   Скрыто       Активно     Активно  Активно     Активно        Скрыто    Скрыто
// 4 - Вход по паролю         Активно     Скрыто       Скрыто      Активно  Скрыто      Скрыто         Активно   Скрыто