import { useWindowWidth } from "@react-hook/window-size";
import { serverFetch } from "@src/server";
import { userLoaded } from "@src/store/actions";
import { useAppDispatch } from "@src/store/hooks";
import { Button, Form, Input, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';

const BookingForm = ({...props}) => {
  const { orderData, setOrderData, readyToBook, onBook } = props;
  const [form] = Form.useForm();
  const isMobile: boolean = useWindowWidth() <= 1130;
  const user = useSelector((s:any) => s.user);
  const d = useAppDispatch();

  const onValuesChange = (a: any) => setOrderData({ ...orderData, ...a });

  const onLogout = () => serverFetch('auth/logout', {method: 'POST'})
        .then(() => d(userLoaded(undefined)))
        .catch(() => d(userLoaded(undefined)));

  const onSendCode = async () => {
    const email = form.getFieldValue("email");
    serverFetch(`Auth/GenerateCheckcode`, {method: 'POST', bodyData: { email }})
        .then(() => setOrderData({...orderData, codeSent: true }))
        .catch(e => message.error(`Ошибка отправки кода ${e.userMessage || ''} `));
  };

  useEffect(() => form.setFieldsValue(orderData), [form, orderData]);
  console.log(orderData)
  const onCheckCode = async () => {
    const email = form.getFieldValue("email");
    serverFetch(`Auth/signin`, {method: 'POST', bodyData: { email, checkcode: orderData.code } })
        .then((data) => {
            localStorage.setItem('refreshToken', data.refreshToken);
            var result:any = jwt_decode(data.accessToken);
            let session = {
                userId: result.userId,
                touristName: result.touristName,
                touristSurname: result.touristSurname,
                touristPatronymic: result.touristPatronymic,
                email: result.email,
                touristPhone: result.touristPhone,
            };
            d(userLoaded(session));
            const element = document.getElementById('booking-order-btn-book');
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        })
        .catch(() => message.error(`Неверный код`));
  };

  return <Form size={'large'} form={form}
    initialValues={{ email: '', code: '', codeSent: false, emailChecked: false, firstName: '', secondName: '', lastName: '', phone: '',}}
    labelCol={{ span: isMobile ? 6 : 4 }} wrapperCol={{ span: isMobile ? 18 : 6 }} layout='horizontal' colon={false}
    onValuesChange={onValuesChange}>
    <Form.Item label='Ваша почта' name='email'><Input placeholder='Введите адрес электронной почты' disabled={orderData.emailChecked} /></Form.Item>
    {orderData.emailChecked && <Form.Item label=' '><Button onClick={onLogout}>Очистить</Button></Form.Item>}
    {!orderData.emailChecked && <Form.Item wrapperCol={{ offset: 4 }}>
      <Button className='action-button' onClick={onSendCode} disabled={orderData.emailChecked}>ОТПРАВИТЬ КОД НА ПОЧТУ</Button>
      {orderData.codeSent && <div>Код отправлен на почту</div>}
      <div style={{fontSize: 12, color: 'gray', marginTop: 4}}>
        <span>Нажимая кнопку Отправить код на почту и Забронировать, Вы соглашаетесь c </span>
        <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2169")}`} target="_blank"><strong>Условиями и правилами</strong></Link>
        <span> и </span>
        <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2170")}`} target="_blank"><strong>Политикой конфиденциальности</strong></Link>.
      </div>
    </Form.Item>}
    {orderData.codeSent && <>
      <Form.Item label='Введите код' name='code'><Input disabled={orderData.emailChecked} placeholder='Введите код из почты' /></Form.Item>
      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button className='action-button' disabled={!orderData.codeSent || orderData.emailChecked} onClick={onCheckCode}>ПРОВЕРИТЬ КОД</Button>
      </Form.Item>
    </>}
    {orderData.emailChecked && <>
      <Form.Item wrapperCol={{ offset: 4 }}><div>Ваши данные</div></Form.Item>
      <Form.Item label='Фамилия' name='secondName'><Input disabled={user && user.touristSurname} /></Form.Item>
      <Form.Item label='Имя' name='firstName'><Input disabled={user && user.touristName} /></Form.Item>
      <Form.Item label='Отчество' name='lastName'><Input disabled={user && user.touristPatronymic} /></Form.Item>
      <Form.Item label='Телефон' name='phone'><MaskedInput mask='+{7}0000000000' disabled={user && user.touristPhone}/></Form.Item>
    </>}
    <Form.Item wrapperCol={{ offset: 4 }}><div>Общая стоимость {orderData.price.toLocaleString('ru-RU')} ₽</div></Form.Item>
    <Form.Item wrapperCol={{ offset: 4 }}>
      <Button className='action-button' disabled={!readyToBook} onClick={onBook} id='booking-order-btn-book'>ЗАБРОНИРОВАТЬ</Button>
    </Form.Item>
  </Form>
}

export default BookingForm;