import React from "react";
import './main-footer.css';
import { Link } from 'react-router-dom'; 

const MainFooter = ( {...props} ) => {
    const { hidden } = props;
    return hidden ? <div id="main-footer" /> : <div className="main-footer" id="main-footer">
        <Link to="https://modul.rr-tour.ru" target="_blank" className="footer-ref">(c) 2012-2024 РУСРЕГИОНТУР</Link>
        <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2170")}`} target="_blank" className="footer-ref">Пользовательское соглашение</Link>
        <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2169")}`} target="_blank" className="footer-ref">Условия и правила</Link>
        <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2171")}`} target="_blank" className="footer-ref">Описание сервиса</Link>
    </div>
}

export default MainFooter;