import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, message, Spin, Radio, Space } from 'antd';
import { serverFetch } from '@src/server';
import { IOptionalService, IRoomCategory, IOptionalProffitService, ICalcAvailResultRoom, IOrder, ICompany } from '@entities/index';
import { IOrderStatus, ICompanyTransKind, IOrderTransVid } from '@enums/index';
import { useWindowWidth } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
import BookingOrderFloat from './booking-order-float';
import LocalFuncs from '@src/extensions/local-funcs';
import { strToInt } from '@src/extensions/utils';
import IBookingOrderData, { BookingOrderDataDefault } from './booking-order-data';
import DataOpers from '@src/extensions/data-opers';
import { MainHeader, MainFooter } from '../main/index';
import SearchRoomsFilter from '../search-categories/search-companies-filter';
import './booking-order.css';
import { RrtCatSlider, Spinner } from '@src/controls';
import BookingGuests from './booking-guests';
import BookingForm from './booking-form';
import BookingCond from './booking-cond';
import { MakeBookingOrderGuests } from './booking-order-guest';

// Окончательное подтверждение бронирования перед созданием заявки в базе
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const BookingOrder = () => {
    // Переданные параметры  
    const { companyId } = useParams();
    // Параметры URL (могут меняться)
    const urlParams = new URLSearchParams(window.location.search);
    // Загруженные параметры при старте
    const [company, setCompany] = useState<ICompany>();
    const [vars, setVars] = useState<Array<ICalcAvailResultRoom>>([]);
    const [roomCategories, setRoomCategories] = useState<Array<IRoomCategory>>([]);
    // Параметры контекста
    const isMobile: boolean = useWindowWidth() <= 1130;
    const isModuleMode = window.self !== window.top;
    const user = useSelector((s:any) => s.user);
    const filter = LocalFuncs.checkFilterPeriod(useSelector((s:any) => s.reservationRoomFilter));
    const [loading, setLoading] = useState(false);
    const [optionalServices, setOptionalServices] = useState<IOptionalService[]>();
    const [orderData, setOrderData] = useState<IBookingOrderData>({
        ...BookingOrderDataDefault,
        companyId: Number(companyId),
        roomCategoryId: strToInt(urlParams.get('roomCategoryId')),
        pricePeriodId: strToInt(urlParams.get('pricePeriodId')),
        roomPlacingId: urlParams.get('roomPlacingId') ?? '',
        promocode: urlParams.get('promocode') ?? '',
        orderTimeId: strToInt(urlParams.get('orderTimeId')),
        debug: urlParams.get('debug') === 'true',
        arrivalDate: filter.arrivalDate,
        departureDate: filter.departureDate,
    });
    const [agreed, setAgreed] = useState<boolean>(false);
    const [agreed2, setAgreed2] = useState<boolean>(false);
    const [booking, setBooking] = useState<boolean>(false);
    const [optionalProffitSerives, setOptionalProffitSerives] = useState<IOptionalProffitService[]>([]);
    const [selectedPit, setSelectedPit] = useState<number>(0);

    //console.log(orderData)

    useEffect(() => DataOpers.getCompany(companyId, setCompany), [companyId]); // Получить компанию
    useEffect(() => DataOpers.getOptionalServices(orderData.pricePeriodId, setOptionalServices, 'Получение услуг'), [orderData.pricePeriodId]); // Получение типов питания для Проффита
    useEffect(() => DataOpers.getCategories(companyId, setRoomCategories, 'Получение категорий'), [companyId]);

    // Получить варианты питания и лечения если это не Алеан
    useEffect(() => {
        company?.aleanSystem || serverFetch(`Booking/GetTariffServices/${orderData.pricePeriodId}/${orderData.roomCategoryId}`, { method: 'GET' })
           .then((data) => {
            console.log(data)
               setOptionalProffitSerives(data);
               if (!data.find((item2: IOptionalProffitService) => item2 && item2.id === selectedPit)) {
                   const item = data.find((item2: IOptionalProffitService) => item2 && item2.isDefault);
                   if (item) setSelectedPit(item.id);
               }
           })
           .catch(e => message.error(`Ошибка получения услуг ${e.userMessage || ''} `));        
    }, [company, orderData.roomCategoryId]); // company,roomCategoryId

    // Получить варианты размещения в компании по фильтру по всем категориям с сортировкой по стоимость по возрастанию для определения следущей ступени улучшения 
    useEffect(() => {
        setLoading(true);
        const calcAsk = {
            adults: Number(filter.adultsQty),
            arrival: filter.arrivalDate.format('YYYY-MM-DD'),
            companyId,
            departure: filter.departureDate.format('YYYY-MM-DD'),
            tariffId: [],
            tarifs: [],
            userType: 0,
            childrenAges: filter.childAges,
            promocode: orderData.promocode,
            orderTimeId: orderData.orderTimeId,
            vidPitId: selectedPit,
            trace: orderData.debug
        };
        serverFetch(`calc/GetAvailableRooms`, { method: 'POST', bodyData: calcAsk, }) 
            .then((data:any) => {
                const cVars = data.rooms.sort((a: ICalcAvailResultRoom, b: ICalcAvailResultRoom) => a.amountWithDiscount - b.amountWithDiscount);
                setVars(cVars);
                setLoading(false);
            })
            .catch((e) => {
                message.error(`Ошибка получения вариантов размещения ${e.userMessage || ''} `);
                setLoading(false);
            });
    }, []); // [companyId, filter]

    // Установка тарифов для гостей по умолчанию
    useEffect(() => {
        //console.log(vars);
        const cVar = vars.find((i:ICalcAvailResultRoom) => i.roomPricePeriodId === orderData.pricePeriodId && i.roomCategoryId === orderData.roomCategoryId 
            && (!orderData.roomPlacingId || orderData.roomPlacingId === i.aleanRoomPlacing.roomPlacingID));
        //console.log(cVar,optionalProffitSerives);
        setOrderData({
            ...orderData,
            guests: MakeBookingOrderGuests(cVar?.qtyMain ?? 0, cVar?.qtyExtra ?? 0, cVar?.qtyChildrenWithBeds ?? 0, filter.childAges, filter.adultsQty, optionalProffitSerives),
            price: cVar?.amountWithDiscount
        });
    }, [optionalProffitSerives, vars])

    // При смене пользователя (авторизации) подставлять данные пользователя
    useEffect(() => {
        if (user) {
            const newOrderData = { ...orderData, email: user.email, emailChecked: true, firstName: user.touristName, secondName: user.touristSurname, lastName: user.touristPatronymic, phone: user.touristPhone };
            setOrderData(newOrderData);
        }
        else setOrderData({ ...orderData, email: '',code: '', codeSent: false, emailChecked: false, firstName: '', secondName: '', lastName: '', phone: '' });    
    }, [user]);

    const onValuesChange = (a: any) => setOrderData({ ...orderData, ...a });

    // Текущий тариф и категория
    const currentVar = vars && vars.find(i => i.roomPricePeriodId === orderData.pricePeriodId && i.roomCategoryId === orderData.roomCategoryId 
        && (!orderData.roomPlacingId || orderData.roomPlacingId === i.aleanRoomPlacing.roomPlacingID));
    const currentCat = currentVar 
        && roomCategories.find(i => currentVar.roomCategoryExtraId ? i.roomCategoryExtraId === currentVar.roomCategoryExtraId : i.roomCategoryId === orderData.roomCategoryId);
    const divCurrent = <RrtCatSlider currentCat={currentCat} title="Категория размещения"/>
    //console.log(currentVar)
    // Следующая категория размещения - поиск следующего варианта у которого при том же тарифе pricePeriodId больше стоимость
    const nextVar = currentVar && vars.find(i => i.roomPricePeriodId === currentVar?.roomPricePeriodId && i.amountWithDiscount > currentVar.amountWithDiscount);
    const nextCat = nextVar && roomCategories.find(i => nextVar.roomCategoryExtraId ? i.roomCategoryExtraId === nextVar.roomCategoryExtraId : i.roomCategoryId === nextVar.roomCategoryId);
    const goNext = () => {
        const newURL = new URLSearchParams();
        orderData.pricePeriodId && newURL.set('pricePeriodId', nextVar?.roomPricePeriodId + "");
        orderData.roomCategoryId && newURL.set('roomCategoryId', nextVar?.roomCategoryId + "");
        orderData.orderTimeId && newURL.set('orderTimeId', orderData.orderTimeId + "");
        let url = `/booking/order/${companyId}?`;
        window.location.href = url + newURL.toString();
        //navigate(url + newURL.toString());
    }
    const divNext = nextCat && <RrtCatSlider currentCat={nextCat} title="Повысить комфортность размещения?"
        actTitle="ПОВЫСИТЬ КАТЕГОРИЮ НОМЕРА" actClick={goNext} actPrice={nextVar.amountWithDiscount - currentVar.amountWithDiscount}/>

    // Переход в поиск по компании
    const goCompany = () => window.location.href = `/booking/search/category/${companyId}`;
    
    // Создание заявки
    const onBook = () => {
        setBooking(true);
        const selectedPitObj = optionalProffitSerives && optionalProffitSerives.find((item) => item.id === selectedPit);
        const vidPitName = selectedPitObj ? selectedPitObj.name : '';
        const roomPricePeriodId = currentVar ? currentVar.roomPricePeriodId : 0;
        let proffitRoomCategoryId = 0;
        if (company?.proffitSystem && currentCat) proffitRoomCategoryId = currentCat.proffitId;
        else if (company?.aleanSystem && currentCat) proffitRoomCategoryId = currentVar.roomCategoryId ?? 0;
        let arrivalTime = roomPricePeriodId ? dayjs(filter.arrivalDate) : null;
        let departureTime = roomPricePeriodId ? dayjs(filter.departureDate) : null;
        if (company?.aleanSystem && currentVar && currentVar.aleanVisit && currentVar.aleanVisit.begin) arrivalTime = currentVar.aleanVisit.begin;
        if (company?.aleanSystem && currentVar && currentVar.aleanVisit && currentVar.aleanVisit.end) departureTime = currentVar.aleanVisit.end;
        
        const order: IOrder = {
            orderId: 0,
            orderDateTime: dayjs(),
            companyId: Number(companyId),
            email: orderData.email,
            phone: orderData.phone,
            arrivalDate: filter.arrivalDate.format('YYYY-MM-DD'),
            departureDate: filter.departureDate.format('YYYY-MM-DD'),
            countAdults: Number(filter.adultsQty),
            countChilds: filter.childsQty,
            countChildsWithBeds: currentVar && currentVar.qtyChildrenWithBeds ? currentVar.qtyChildrenWithBeds : 0,
            price: orderData.price ?? 0,
            additionalComments: orderData.extraComment,
            arrivalTime,
            departureTime,
            childAge1: filter.childsQty >= 1 && filter.childAges.length >= 1 ? filter.childAges[0] : 0,
            childAge2: filter.childsQty >= 2 && filter.childAges.length >= 2 ? filter.childAges[1] : 0,
            childAge3: filter.childsQty >= 3 && filter.childAges.length >= 3 ? filter.childAges[2] : 0,
            childAge4: filter.childsQty >= 4 && filter.childAges.length >= 4 ? filter.childAges[3] : 0,
            childAge5: filter.childsQty >= 5 && filter.childAges.length >= 5 ? filter.childAges[4] : 0,
            childAge6: filter.childsQty >= 6 && filter.childAges.length >= 6 ? filter.childAges[5] : 0,
            childAge7: filter.childsQty >= 7 && filter.childAges.length >= 7 ? filter.childAges[6] : 0,
            childAge8: filter.childsQty >= 8 && filter.childAges.length >= 8 ? filter.childAges[7] : 0,
            childAge9: filter.childsQty >= 9 && filter.childAges.length >= 9 ? filter.childAges[8] : 0,
            childAge10: filter.childsQty >= 10 && filter.childAges.length >= 10 ? filter.childAges[9] : 0,
            roomPricePeriodId,
            isProffit: true,
            proffitRoomCategoryId,
            orderStatus: IOrderStatus.New,
            payment: 0,
            touristFirstName: orderData.firstName,
            touristSecondName: orderData.secondName,
            touristLastName: orderData.lastName,
            orderNumber: '',
            touristName: '',
            orderTimeId: orderData.orderTimeId,
            vidPitId: selectedPit,
            vidPitName,
            transVid: orderData.transVid,
            transName: orderData.transName,
            guests: orderData.guests,
            aleanLine: currentVar?.aleanVisit?.line,
            aleanSessionId: currentVar?.aleanSessionId,
            aleanRoomPlacing: currentVar?.aleanRoomPlacing,
            aleanTouristTypes: currentVar?.aleanTouristTypes,
            roomCategoryExternalId: currentVar?.roomCategoryExtraId,
            roomCategoryName: currentVar?.roomCategoryName,
            tariffName: currentVar?.tariffName,
            roomCategoryId: currentVar?.roomCategoryId,  
        };
        serverFetch(`Booking/Book`, { method: 'POST', bodyData: order })
            .then((orderId: string) => {
                console.log(`Сформирован заказ ${orderId}`);
                const url = `${window.location.origin}/booking/${orderId}`;
                window.location.href = url;
            })
            .catch((e) => {
                setBooking(false);
                message.error(`Ошибка формирования заказа ${e.userMessage || ''} `);
            });
    };

    const needTrans = () => company && company.transKind === ICompanyTransKind.PublicAndCar && orderData && (orderData.transVid !== IOrderTransVid.Car) && (orderData.transVid !== IOrderTransVid.Public);
    const divTrans = company && company.transKind === ICompanyTransKind.PublicAndCar && <div className='reserve-section'>
        <div className='reserve-section-title'>Трансфер</div>
        <Radio.Group onChange={(e) => onValuesChange({transVid: e.target.value})} value={orderData.transVid}>
            <Space direction="vertical">
                <Radio value={1}>Общественный транспорт</Radio>
                <Radio value={2}>Собственный автомобиль. Гос.номер {orderData.transVid === 2 ? 
                    <Input style={{ width: 100, marginLeft: 10 }} placeholder='X000XX' onChange={(e) => onValuesChange({transName: e.target.value})}/> : null}
                </Radio>
            </Space>
        </Radio.Group>
        {needTrans() && (<div className='reserve-agreed'>Для продолжения бронирования необходимо выбрать вариант трансфера</div>)}
    </div>
    const fullAgree = agreed && agreed2; // Согласие со всеми условиями
    const readyToBook = orderData.emailChecked && orderData.firstName && orderData.secondName && orderData.phone; // Данные туриста заполнены
    
    //console.log(orderData);
    const divFloat = currentVar && <BookingOrderFloat currentTariff={currentVar} orderData={orderData} optionalProffitSerives={optionalProffitSerives} selectedPit={selectedPit}
        setSelectedPit={setSelectedPit} optionalServices={optionalServices} company={company} booking={booking} setOrderData={setOrderData} />
    return (<>
        {!isModuleMode && <MainHeader />}
        <div className='search-companies-container'>
            {isModuleMode && <div className='search-buy-put'>КУПИТЬ ПУТЁВКУ</div>}
            <SearchRoomsFilter onSearch={() => {}} loading={false} searched={true} collapsed={true} setFilterCollapsed={goCompany} isReady={true} clearResults = {null} onSelectCategory={null} />
            {loading ? <Spinner /> : currentVar ? <div className='reserve'>
                <div className='booking-order-main'>
                    <div className='reserve-section'>{isMobile ? divCurrent : <div className='reserve-column2'>{divCurrent}</div>}</div>
                    {nextVar && nextCat && !booking && <div className='reserve-section'>{isMobile && <>{divNext}</>}{!isMobile && <div className='reserve-column2'>{divNext}</div>}</div>}
                    
                    <BookingGuests optionalServices={optionalProffitSerives} orderData={orderData} includedServices={optionalServices} 
                        tariff={currentVar} setOrderData={setOrderData} companyId={companyId}/>
                    {isMobile && divFloat}
                    {divTrans}
                    {!needTrans() && (orderData?.price ?? 0) > 0 && <>
                        <BookingCond company={company} fullAgree={fullAgree} agreed={agreed} agreed2={agreed2} setAgreed={setAgreed} setAgreed2={setAgreed2} booking={booking} />
                        {fullAgree && <div className='reserve-section'>
                            <div className='reserve-section-title'>Оформление заявки</div>
                                <Spin spinning={booking} tip='Подтверждение брони...'>
                                    {currentVar && <BookingForm orderData={orderData} setOrderData={setOrderData} readyToBook={readyToBook} onBook={onBook} price={currentVar.amountWithDiscount} />}
                                </Spin>
                            </div>}
                        </>
                    }
                </div>
                {!isMobile && divFloat}
            </div> : <div className='reserve-section'><div className='reserve-section-title'>Нет вариантов для бронирования</div></div>}
        </div>
        <MainFooter hidden={isModuleMode}/>
    </>);
};

export default BookingOrder;