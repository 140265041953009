import { IOrderTransVid } from "@src/store/enums/order-trans-vid";
import IBookingOrderGuest from "./booking-order-guest";
// Данные пользователя, указанные в путевке
export default interface IBookingOrderData {
    email: string;
    code: string;
    codeSent: boolean;
    emailChecked: boolean;
    firstName: string;
    secondName: string;
    lastName: string;
    phone: string;
    originalCode: string;
    extraComment: string;
    touristId?: number;
    transVid?: IOrderTransVid;
    transName?: string;
    guests: IBookingOrderGuest[];
    price?: number;
    companyId: number;
    roomCategoryId: number;
    pricePeriodId: number;
    roomPlacingId: string; // Алеан
    promocode: string;
    orderTimeId: number;
    debug: boolean;
    arrivalDate?: any; // Dayjs
    departureDate?: any; // Dayjs
}

export const BookingOrderDataDefault:IBookingOrderData = {
    email: '',
    code: '',
    codeSent: false,
    emailChecked: false,
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    originalCode: '',
    extraComment: '',
    guests: [],
    roomCategoryId: 0,
    companyId: 0,
    pricePeriodId: 0,
    roomPlacingId: '',
    promocode: '',
    orderTimeId: 0,
    debug: false,
}