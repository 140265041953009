import React, { useState } from "react";
import './booking-guests.css';
import { Input, message, Spin } from "antd";
import { getGuestPlaсe, getGuestTitle } from "./booking-order-guest";
import { RrtBtnSwitch, RrtTag, RrtTab } from "@src/controls";
import { serverFetch } from "@src/server";

const { TextArea } = Input;

const BookingGuests = ({...props}) => {
  const { orderData, optionalServices, includedServices, tariff, setOrderData } = props;
  const [selected, setSelected] = useState(1);
  const selectedItem = orderData.guests.find(i => i.num === selected);

  const orRecalc = (newOrderData) => {
    setOrderData(newOrderData);
    const data = {
      ...newOrderData,
      arrival: newOrderData.arrivalDate.format('YYYY-MM-DD'),
      departure: newOrderData.departureDate.format('YYYY-MM-DD'),
    };
    //console.log(data);
    serverFetch("calc/CalcOrder", { method: "POST", bodyData: data })
      .then(data => {
        setOrderData({...newOrderData, price: data.price});
      })
      .catch(data => {
        setOrderData({...newOrderData, price: 0});
        console.log(data.userMessage);
        message.error("Тариф не может быть применен");
      })
  }

  const onSetPit = (id, name) => {
    const newItems = orderData.guests.map(i => i.num === selected ? {...i, pit: id, pitName: name} : i );
    orRecalc({...orderData, guests: newItems, price: null});
  }

  const onSetLech = (id, name) => {
    const newItems = orderData.guests.map(i => i.num === selected ? {...i, lech: id, lechName: name} : i );
    orRecalc({...orderData, guests: newItems, price: null});
  }

  const divPrice = (price) => {
    if (price == null) return <Spin />
    if (price > 0) return<div className="total">ИТОГО {price.toLocaleString('ru-RU')} ₽</div> 
    return <div className="total">Тариф не подобран</div>
  }

  const servicePit = optionalServices?.filter(i => i.vid === 5);
  const serviceLech = optionalServices?.filter(i => i.vid === 7);

  return <div className="booking-quests">
    <div>
      <div className="row">
        <div className="label">ТАРИФ</div>
        <RrtBtnSwitch label={tariff?.tariffName} selected/>
      </div>
      <div className="row">{includedServices?.map(i => <RrtTag label={i.name} key={i.baseOptionalServiceId}/>)}</div>
    </div>
    <div>
      <div className="row">
        <div className="label">ДОПОЛНИТЕЛЬНО</div>
      </div>
      <div className="row">{orderData.guests.map(i => 
        <RrtTab label={getGuestTitle(i)} sublabel={getGuestPlaсe(i)} selected={i.num === selected} disabled={i.seat === undefined} onClick={() => setSelected(i.num)} key={i.num}/>
      )}</div>
      <div className="row">
        <div className="label">ГОСТЬ {selected}</div>
      </div>
      {servicePit && servicePit.length > 0 && <div className="row">
        <div className="label">ПИТАНИЕ</div>
        {servicePit.map((i) => <RrtBtnSwitch label={i.name} key={i.id} selected={i.id === selectedItem?.pit} onClick={() => onSetPit(i.id, i.name)} />)}
      </div>}
      {serviceLech && serviceLech.length > 0 && <div className="row">
        <div className="label">ЛЕЧЕНИЕ</div>
        {serviceLech.map(i => <RrtBtnSwitch label={i.name} key={i.id} selected={i.id === selectedItem?.lech} onClick={() => onSetLech(i.id, i.name)}/>)}
      </div>}
    </div>
    <div>
      <div className="row">
        <div className="label">ПОЖЕЛАНИЯ</div>
      </div>
      <TextArea 
            autoSize={{ minRows: 4}} 
            value={orderData?.extraComment} 
            onChange={(e) => setOrderData({ ...orderData, extraComment: e.target.value })}
        />
    </div>
    <div>
      <div className="row">{divPrice(orderData.price)}</div>
    </div>
  </div>
}

export default BookingGuests;