import React from "react";
import './search-home-block.css';

const SearchHomeBlock = ({...props}) => {
  const { link, text, img } = props;

  return<div className="search-home-block">
    <img src={img} />
    <div className="search-home-col">
      <div>{text}</div>
      <a href={link}>Подробнее</a>
    </div>
  </div>
}

export default SearchHomeBlock;