export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function value4query(key, value) {
    if (value === null || value === undefined) {
        return '';
    }

    return value; //encodeURIComponent(value);
}

export async function serverFetch(address, options) {
    options = options || {};

    let url = new URL(`${apiBaseUrl}/api/${address}`);
    Object.keys(options.queryParams || {}).forEach((key) => {
        if (Array.isArray(options.queryParams[key])) {
            options.queryParams[key].map((value) => url.searchParams.append(key, value4query(key, value)));
        } else {
            url.searchParams.append(key, value4query(key, options.queryParams[key]));
        }
    });

    let init = {
        method: 'GET',
        ...options,
        headers: options.body ? new Headers() : new Headers({ accept: 'application/json', 'Content-Type': 'application/json-patch+json' }),
    };

    // console.log('window.accessToken123', window.accessToken);
    init.headers.append('Authorization', `Bearer ${window.accessToken}`);

    if (options.bodyData != null) {
        init.body = JSON.stringify(options.bodyData);
    }

    if (options.headers) {
        Object.keys(options.headers).forEach((key) => {
            init.headers.append(key, options.headers[key]);
        });
    }

    let tokenRefreshed = false;

    let response;
    do {
        try {
            response = await fetch(url, init);
            //console.log(response);
            //console.log('123', !!response.headers.get('www-authenticate'));
            if (!tokenRefreshed && response.status === 401 && !!response.headers.get('www-authenticate')) {
                //console.log("1");
                if (address !== 'auth/signin') {
                    response = await tryRefreshAuthToken();
                    //console.log(response);
                    if (response.ok) {
                        tokenRefreshed = true;
                        init.headers.set('Authorization', `Bearer ${window.accessToken}`); // TODO replace with proper storage
                    }
                    else if (response.status === 401) {
                        console.log("Закрытие сессии");
                        if (options.logout) options.logout(); 
                        //d(userLoaded(undefined))
                    }
                }
            } else {
                tokenRefreshed = false;
            }
        } catch (exc) {
            //console.log('error fetch', exc);
            //console.error(exc);
            throw new Error('Error parsing response body');
        }
    } while (tokenRefreshed);

    if (response.ok && response.status === 204) {
        return;
    }

    let responseContentPromise;
    if (!response.ok || options.rawResponse) {
        responseContentPromise = response.text();
    } else if (options.download || options.blob) {
        responseContentPromise = response.blob();
    } else {
        responseContentPromise = response.json();
    }

    //console.log('server wait content');
    let content;
    try {
        content = await responseContentPromise;
        //console.log('server content', content);
    } catch (exc) {
        console.error(exc);
        throw new Error('Error parsing response body');
    }

    if (response.ok) {
        return content;
    }

    if (!content) {
        content = {
            status: response.status,
            message: response.statusText,
        };
    } else if (typeof content == 'string') {
        try {
            content = JSON.parse(content);
            console.log('contentParsed', content);
        } catch {
            // ignore
        }
    }

    throw content;
}

async function tryRefreshAuthToken() {
    let url = new URL(`${apiBaseUrl}/api/auth/refreshtoken`);

    let init = {
        method: 'POST',
        body: JSON.stringify({ token: localStorage.getItem('refreshToken') }),
        headers: new Headers({
            accept: 'application/json',
            'Content-Type': 'application/json-patch+json',
        }),
    };

    try {
        let response = await fetch(url, init);

        if (response.ok) {
            var content = await response.json();
            window.accessToken = content.accessToken; // TODO replace with proper storage
            localStorage.setItem('refreshToken', content.refreshToken); // TODO replace with proper storage
        }
        return response; // TODO replace with proper storage
    } catch (ex) {
        throw ex;
    }
}
