import React from "react";
import SearchHomeFilter from "./search-home-filter";
import MainHeader from "../main/main-header";
import MainFooter from "../main/main-footer";
import './search-home.css';
import { useWindowWidth } from "@react-hook/window-size";

const SearchHome = () => {
    const isMobile = useWindowWidth() <= 992;

    const bgimg = isMobile ? "url(\"/images/search-bgm.png\") no-repeat" : "url(\"/images/search-bg.png\") no-repeat";

    return<>
    <MainHeader />
    <div className="search-home-main" style={{ background: bgimg, backgroundPosition: "top center" }}>        
        <SearchHomeFilter />        
    </div>
    <MainFooter hidden={false}/>
    </>
}

export default SearchHome;