import { fmtDateFull, weekDayToStr } from "@src/extensions/utils";
import dayjs from "dayjs";
import React from "react";
import AleanPlaces from "../search-categories/alean-places";
import { Spin } from "antd";

const BookingOrderFloat = ({...props}) => {
    const { currentTariff, orderData, optionalProffitSerives, optionalServices, company } = props;
    const orderTime = company?.orderTimes?.find((d) => d.orderTimeId === orderData.orderTimeId);
    const addDay = orderTime && orderTime.isFullDay ? 1 : 0;

    const childsQty = orderData.guests.filter(i => i.age > 0).length;
    const adultsQty = orderData.guests.length - childsQty;

    const aPits = [];
    const aLech = [];
    orderData.guests.forEach(e => {
        if (e.pit) {
            const idx = aPits.findIndex(i => i.id === e.pit);
            if (idx === -1) aPits.push({id: e.pit, qty: 1, name: optionalProffitSerives.find(i => i.vid === 5 && i.id === e.pit).name});
            else aPits[idx].qty++;
        }
        if (e.lech) {
            const idx = aLech.findIndex(i => i.id === e.lech);
            if (idx === -1) aLech.push({id: e.lech, qty: 1, name: optionalProffitSerives.find(i => i.vid === 7 && i.id === e.lech).name});
            else aLech[idx].qty++;
        }
    });
        
    const divPit = aPits.length > 0 && <div>
        <div className='booking-order-float-item booking-order-float-caption'>Питание</div>
        {aPits.map(i => <div className='booking-order-float-item' key={i.id}>{`${i.name} x ${i.qty}`}</div>)}
    </div>
    const divLech  = aLech.length > 0 && <div>
        <div className='booking-order-float-item booking-order-float-caption'>Лечение</div>
        {aLech.map(i => <div className='booking-order-float-item' key={i.id}>{`${i.name} x ${i.qty}`}</div>)}
    </div>

    const divIncluded =
        optionalServices &&
        optionalServices.map((x) => (
            <div className='booking-order-float-item' key={x.baseOptionalServiceId}>
                {x.name}
            </div>
        ));

    const divPrice = (price) => {
        if (price == null) return <Spin />
        if (price > 0) return<div className="reserver-hotel-tariff-price">{price.toLocaleString('ru-RU')} ₽</div> 
        return <div className="reserver-hotel-tariff-price">Тариф не подобран</div>
    }

    return<div className='booking-order-float'>
        <div className='reserve-section-title'>Ваша заявка на бронирование</div>
        <div className='booking-order-float-item booking-order-float-caption'>Размещение</div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Заезд</div>
            <div>
                {weekDayToStr(dayjs(orderData.arrivalDate).day())} {fmtDateFull(dayjs(orderData.arrivalDate))}{' '}
                {orderTime && orderTime.arrivalTime.toString().substr(0, 5)}
                {currentTariff && currentTariff.aleanVisit && currentTariff.aleanVisit.begin && dayjs(currentTariff.aleanVisit.begin).format("HH:mm")}
            </div>
        </div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Выезд</div>
            <div>
                {weekDayToStr(dayjs(orderData.departureDate).day())} {fmtDateFull(dayjs(orderData.departureDate))}{' '}
                {orderTime && orderTime.departureTime.toString().substr(0, 5)}
                {currentTariff && currentTariff.aleanVisit && currentTariff.aleanVisit.end && dayjs(currentTariff.aleanVisit.end).format("HH:mm")}
            </div>
        </div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Срок</div>
            <div>{dayjs(orderData.departureDate).diff(dayjs(orderData.arrivalDate), 'day', true) + addDay} суток</div>
        </div>

        <div className='booking-order-float-item booking-order-float-caption'>Гости</div>
        
        {currentTariff && currentTariff.aleanRoomPlacing 
            ? <div className='booking-order-float-item'><AleanPlaces aleanRoomPlacing={currentTariff.aleanRoomPlacing} aleanTouristTypes={currentTariff.aleanTouristTypes}/></div>
            : <div className='booking-order-float-item'>{adultsQty} взрослых {childsQty > 0 && `+ ${childsQty} детей`}</div>
        }

        <div className='booking-order-float-item booking-order-float-caption'>Тариф</div>
        <div className='booking-order-float-item'>{currentTariff && currentTariff.tariffName}</div>
        <div className='booking-order-float-item'>
            <div className='booking-order-float-label'>Включено</div>
        </div>
        {divIncluded}
        {divPit}
        {divLech}
        {/* {divOptionalServices} */}
        {orderData.extraComment && <div>
        <div className='booking-order-float-item booking-order-float-caption'>Пожелания</div>
        <div>{orderData.extraComment}</div>
        </div>}
        {divPrice(orderData.price)}
    </div>
}

export default BookingOrderFloat;