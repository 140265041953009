import React from "react";
import './rrt-tab.css';

const RrtTab = ({...props}) => {
  const {label, sublabel, selected, disabled, onClick} = props;

  const tabClass = "rrt-tab" + (selected ? " selected" : "") + (disabled ? " disabled" : "")
  return <div className={tabClass} onClick={disabled ? null : onClick}>
    <div className="rrt-label">{label}</div>
    <div className="rrt-sublabel">{sublabel}</div>
  </div>


}

export default RrtTab;