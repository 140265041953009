import { IOptionalProffitService } from "@src/store/entities";

export default interface IBookingOrderGuest {
    age?: number; // null - Взрослый
    seat?: number; // 0 - Основное, 1 - Дополнительное, null - Без места
    pit?: number;
    lech?: number;
    num: number;
    pitName?: string;
    lechName?: string;
}

export const MakeBookingOrderGuests = (
  mainBeds:number,
  extraBeds:number,
  qtyChildrenWithBeds:number,
  childAges:number[],
  qtyAdults:number,
  optionalProffitSerives:IOptionalProffitService[]
) => {
    //console.log("MakeBookingOrderGuests", mainBeds, extraBeds, qtyChildrenWithBeds, qtyAdults, childAges);
    //const qtyAdults = mainBeds + extraBeds - qtyChildrenWithBeds;
    let items:IBookingOrderGuest[] = [];
    let num = 0;
    for (let i = 0; i < qtyAdults; i++){
        num++;
        items.push({ num, seat: num <= mainBeds ? 0 : 1 });
    }
    childAges.sort((a, b) => b - a).forEach(i => {
        num++;
        items.push({ num, age: i, seat: num <= mainBeds ? 0 : (num <= mainBeds + extraBeds ? 1 : undefined) });
    })
    const pit = optionalProffitSerives.find(i => i.isDefault && i.vid === 5);
    const lech = optionalProffitSerives.find(i => i.isDefault && i.vid === 7);
    items.filter(i => i.seat !== undefined).forEach(i => {i.pit = pit?.id; i.pitName = pit?.name; i.lech = lech?.id; i.lechName = lech?.name; })
    return items;
}

export const getAgeString = (age:number) => {
  switch (age) {
    case 1: return `${age} ГОД`;
    case 2: case 3: case 4: return `${age} ГОДА`;
    default: return `${age} ЛЕТ`
  }
}

export const getGuestTitle = (item:IBookingOrderGuest) => {
  return item.age ? `РЕБЕНОК ${getAgeString(item.age)}` : 'ВЗРОСЛЫЙ';
}

export const getGuestPlaсe = (item:IBookingOrderGuest) => {
  switch (item.seat) {
    case 0:
      return "основное место";
    case 1:
      return "доп место";
    default:
      return "без места";
  }
}