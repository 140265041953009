import React from "react";
import './rrt-btn-switch.css';

const RrtBtnSwitch = ({...props}) => {
  const {label, selected, onClick} = props;
  const buttonClass = selected ? "rrt-btn-switch selected" : "rrt-btn-switch";
  return<div className={buttonClass} onClick={onClick}>
    <div className="point" />
    <div className="text">{label}</div>
  </div>
}

export default RrtBtnSwitch;