import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IInfrastructure } from "@src/store/entities";
import { Button, Carousel, Skeleton, Tooltip } from "antd";
import { CarouselRef } from "antd/es/carousel";
import React, { useRef } from "react";
import { useElementSize } from "usehooks-ts";

const RrtCatSlider = ({...props}) => {
  const {currentCat, title, actTitle, actClick, actPrice} = props;
  const ref0 = useRef<CarouselRef>(null);
  const [squareRef, { width }] = useElementSize();

  const SlickArrowLeft0 = () => <Button aria-hidden='true' shape='circle' icon={<LeftOutlined />} className="slick-arrow slick-prev" onClick={() => ref0.current?.prev()}/>
  const SlickArrowRight0 = () => <Button aria-hidden='true' shape='circle' icon={<RightOutlined />} className="slick-arrow slick-next" onClick={() => ref0.current?.next()}/>
  const contentStyle: React.CSSProperties = { margin: 0, width: width, height: 230, color: '#fff', lineHeight: '160px', textAlign: 'center', background: '#364d79' };

  return currentCat ? <>
  <div className='reserve-column' style={{ width: 350 }}>
      <div className='reserve-section-title'>{title}</div>
      <div style={{ width: 350 }} ref={squareRef} className='reserver-hotel-gallery'>
          {currentCat && currentCat.gallery && currentCat.gallery.length > 0 ? <Carousel arrows={true} prevArrow={<SlickArrowLeft0 />} nextArrow={<SlickArrowRight0 />} slidesToScroll={1} ref={ref0}>
              {currentCat && currentCat.gallery?.map((i:any) => <div key={i.imageId}>
                  <img style={contentStyle} src={i.source ? i.source : `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`} alt="Фото категории"/>
              </div>)}
          </Carousel> : <Skeleton.Image active />}
      </div>
      <div className='reserve-hotel-infra'>
          {currentCat &&
              currentCat?.infrastructure &&
              currentCat?.infrastructure.map((e: IInfrastructure) => <Tooltip title={e.name} key={e.infrastructureID}>
                  <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID} alt="Инфр."/>
              </Tooltip>)}
      </div>
  </div>
  <div className='reserve-column'>
    <div className='reserve-section-title'>{currentCat && currentCat.name}</div>
    <div className='reserve-hotel-description'>{currentCat && currentCat.description}</div>
    {actPrice && <div className='reserver-tariff'>
    <div className='reserver-hotel-tariff-price'>+ {actPrice.toLocaleString('ru-RU')} ₽</div>
    </div>}
    {actTitle && <Button onClick={actClick} className='action-button'>{actTitle}</Button>}
  </div>
</> : <div className='reserve-section-title'>Категория размещения не выбрана</div> 
}

export default RrtCatSlider;