import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './main-layout';
import Booking from '@components/booking';
import SearchCategories from '@components/search-categories/search-categories';
import '@fontsource-variable/oswald';
import './index.css';
import SearchRegion from './components/search-region/search-region';
import SearchHome from './components/search-home/search-home';
import Sales from './components/sales/sales';
import Article from './components/article/article';
import BookingOrder from './components/booking-order';
import NotFound from './components/not-found/not-found';

const App = () => {
    document.title = "Сервис онлайн бронирования КУПИТЬ ПУТЁВКУ";
    return (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route path='/' element={<SearchHome />} />
                <Route path={'booking/:id'} element={<Booking />} />
                <Route path='booking/order/:companyId' element={<BookingOrder />} />
                <Route path={'booking/search/category/:companyId'} element={<SearchCategories />} />
                <Route path={'module/booking/search/category/:companyId'} element={<SearchCategories />} />
                <Route path={'home/company/:companyId'} element={<SearchCategories />} />
                <Route path={'booking/search/region/:regionAlias'} element={<SearchRegion />} />
                <Route path='sales' element={<Sales />} />
                <Route path='article/:url' element={<Article />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default App;