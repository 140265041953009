import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./search-home-filter.css";
import SearchFilterDate from "./search-filter-date";
import dayjs from "dayjs";
import { serverFetch } from "@src/server";
import { GuestsControl } from "@src/controls";
import { useNavigate  } from 'react-router-dom';
import { useWindowWidth } from "@react-hook/window-size";
import { withoutTime } from "@src/extensions/utils";
import LocalFuncs from "@src/extensions/local-funcs";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@src/store/hooks";
import { categoryFilterChanged } from "@src/store/actions";
import SearchHomeBlock from "./search-home-block";

const SearchHomeFilter = () => {
    const d = useAppDispatch();
    const [searchedItems, setSearchedItems] = useState([]);
    const [showSearched, setShowSearched] = useState(false);
    const [selectingItems, setSelectingItems] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedItem, setSelectedItem] = useState();
    const [filter, setFilter] = useState(LocalFuncs.checkFilterPeriod(useSelector((s) => s.reservationRoomFilter))); // Получить скорректированный сохраненный фильтр
    const navigate = useNavigate ();
    const isMobile = useWindowWidth() <= 992;
    const onSelectRegion = (value) => {
        setSelectedItem(value);
        if (value) {
            setSearchText(value.regionName);
            setFilter({...filter, regionCode: value.urlName})
        }
        setSelectingItems(false);
    }
    const onSearch = (e) => {
        setSelectedItem(null);
        const value = e ? e.target.value : "";
        setSearchText(value);
        serverFetch(`region/search?searchtext=${value}`, { method: 'GET' })
            .then(data => setSearchedItems(data))
            .catch(ex => console.log('Ошибка получения списка', ex));
    }
    useEffect(onSearch, []);
    const divSearchedItems = searchedItems.map(item => <div key={item.regionName} className="search-home-filter-searched-item" 
        onClick={() => onSelectRegion(item)}>
        {item.regionName}
    </div>)

    const divSearched = (showSearched || selectingItems) && <div className="search-home-filter-searched-items" 
        onMouseEnter={() => setSelectingItems(true)}
        onMouseLeave={() => setSelectingItems(false)}>
        {divSearchedItems}
    </div>
    const disabledDate = (current) => current && dayjs(current) < withoutTime(dayjs())
    const disabledDateEnd = (current) => current && (dayjs(current) < withoutTime(dayjs()) || current < filter.arrivalDate)
    const onChangeDateBeg = (arrivalDate) => {
        const srok = filter.departureDate.diff(filter.arrivalDate, 'days');
        const departureDate = arrivalDate.add(srok, 'days');
        setFilter({...filter, arrivalDate, departureDate})
    }
    const onChangeDateEnd = (departureDate) => setFilter({...filter, departureDate})

    const onGo = () => {
        d(categoryFilterChanged(filter))
        navigate(`/booking/search/region/${selectedItem?.urlName}`)
    }
    return  <>
        <div className="search-home-filter">
            <div className="search-home-filter-title">Путешествуйте по России с нами</div>
            <div className="search-home-filter-body">
                <div className="search-home-filter-segment">
                    <img src="/images/search.svg" alt="pic" />
                    <Input placeholder="Введите название региона" className="search-home-input" 
                        onFocus={() => setShowSearched(true)}
                        onBlur={(e) => setShowSearched(false)}
                        value={searchText}
                        onChange={onSearch}
                    />
                </div>
                {divSearched}
                <div className="search-home-filter-segment">
                    <SearchFilterDate title="ЗАЕЗД" value={filter.arrivalDate} onChange={onChangeDateBeg} disabledDate={disabledDate}/>
                    <SearchFilterDate title="ВЫЕЗД" value={filter.departureDate} onChange={onChangeDateEnd} disabledDate={disabledDateEnd}/>
                </div>
                <div className="search-home-filter-segment">
                    <GuestsControl filter={filter} setFilter={setFilter} openPopover={openPopover} handleOpenPopoverChange={setOpenPopover}/>
                </div>
                {isMobile ? <div className="search-home-filter-segment">
                      <Button className="search-home-button" disabled={!selectedItem} onClick={onGo}>Найти</Button></div> 
                    : <Button className="search-home-button" disabled={!selectedItem} onClick={onGo}>Найти</Button>}                
            </div>
        </div>
        <div className="search-home-text">
        Сервис КУПИТЬ ПУТЁВКУ предлагает отдых и лечение на лучших курортах. 
        Бронирование отелей, гостиниц, санаториев и баз отдыха онлайн с гарантированным размещением и по официальным ценам всегда доступны 
        на нашем сайте <a href="https://rr-tour.ru">rr-tour.ru</a>. 
        Мы занимаемся автоматизацией онлайн бронирования с 2012 года!
        </div>
        {/* {!isMobile && <div className="search-home-title">ВАША СИСТЕМА ОНЛАЙН БРОНИРОВАНИЯ</div>} */}
        {!isMobile && <img src="/images/main-splash.png" style={{marginTop: 32}}/>}
        <div className="search-home-title">СТАНЬТЕ ПАРТНЁРОМ СЕРВИСА КУПИТЬ ПУТЁВКУ</div>
        <div className="search-home-blocks">
            <SearchHomeBlock title="Добавьте свой объект размещения" text="Получайте больше бронирований от туристов на вашем сайте, в сообществе, в поиске по региону"
                img="/images/247.png"
                link="https://modul.rr-tour.ru/" />
            <SearchHomeBlock title="Откройте онлайн продажи на вашем сайте и в сообществе" 
                text="Установите модуль бронирования КУПИТЬ ПУТЁВКУ на выгодных условиях"
                img="/images/direct.png"
                link="https://modul.rr-tour.ru/"/>
        </div>
    </>
}

export default SearchHomeFilter;