import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React from "react";
import { Link } from "react-router-dom";


const BookingCond = ({...props}) => {
  const { company, fullAgree, agreed, agreed2, setAgreed, setAgreed2, booking } = props;

  return<>
    <div className='reserve-section'>
        <div className='reserve-section-title'>Правила аннуляции заявки</div>
        <div>{company?.cancelOrderRuleForTourist}</div>
    </div>
    <div className='reserve-section'>
        <div className='reserve-section-title'>Условия бронирования</div>
        <div className='reserve-info'>{company?.bookingExtraInformationForTourist}</div>
        <div className='reserve-links'>
            {process.env.REACT_APP_DOC_USL && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_USL)}`} target='_blank'>Условия и правила</Link>}
            {process.env.REACT_APP_DOC_AGREEMENT && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_AGREEMENT)}`} target='_blank'>Пользовательское соглашение</Link>}
            {process.env.REACT_APP_DOC_ZAYAVL && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_ZAYAVL)}`} target='_blank'>Заявление на аннуляцию оплаченной путевки</Link>}
        </div>
        {fullAgree || <div className='reserve-agreed'>Для продолжения бронирования необходимо согласиться с условиями</div>}
        <Checkbox className='reserve-agreed' checked={agreed} onChange={(e: CheckboxChangeEvent) => setAgreed(e.target.checked)} disabled={booking}>Подтверждаю согласие с Правилами аннуляции заявки и Условиями бронирования</Checkbox>
        <Checkbox className='reserve-agreed' checked={agreed2} onChange={(e: CheckboxChangeEvent) => setAgreed2(e.target.checked)} disabled={booking}>Подтверждаю согласие на обработку персональных данных</Checkbox>
    </div>                
  </>
}

export default BookingCond;