import React, { useEffect, useState } from "react";
import './main-header.css';
import Auth from "../auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "antd";
import { serverFetch } from "@src/server";
import { userLoaded } from "@src/store/actions";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useWindowWidth } from "@react-hook/window-size";
import Account from "../auth/account";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../auth/change-password";

const MainHeader = () => {
    const d = useDispatch();
    const user = useSelector((s) => s.user);
    const [showSignin, setShowSignin] = useState(window.location.pathname == "/sales" && !user);
    const onlyWidth = useWindowWidth();
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const navigate = useNavigate();

    //console.log(window.location.pathname);

    const logout = () => serverFetch('auth/logout', {method: 'POST'})
        .then(() => d(userLoaded(undefined)))
        .catch(() => d(userLoaded(undefined)));

    const items = [
        {
            key: '1',
            label: (<Button type='link' className='account-item-btn' onClick={() => setShowAccountInfo(true)} size='middle'>Мои данные</Button>),
        },
        { 
            key: '3',
            label: (<Button type='link' className='account-item-btn' onClick={() => navigate(`/sales`)} size='middle'>Путевки</Button>),
        },
        { 
            key: '4',
            label: (<Button type='link' className='account-item-btn' onClick={() => setShowChangePassword(true)} size='middle'>Сменить пароль</Button>),
        },
        {
            key: '2',
            label: (<Button type='link' className='account-item-btn' onClick={() => logout()} size='middle'>Выход</Button>),
        },
    ];

    const divLogin = user ? (
        <Dropdown menu={{ items: items }} placement='bottomRight'>
            <Button type='link' className='main-account-btn' size='middle'>
                <UserOutlined/>{onlyWidth > 720 && (`${user.email}`)}
            </Button>
        </Dropdown>
    ) : (
        <Button type='link' className='main-account-btn' onClick={() => setShowSignin(true)} size='middle' icon={<LockOutlined />}>
            {onlyWidth > 720 && 'Личный кабинет'}
        </Button>
    )
    //console.log(onlyWidth);
    return <div className="main-header">
        <div className="main-header-content">
            <a href="/"><img src="/images/logo.svg" className="logo-svg" /></a>
            {/* <div className="main-header-text">Путешествуйте по России с нами</div> */}
            {divLogin}
        </div>
        {showSignin && <Auth onClose={() => setShowSignin(false)} />}
        {showAccountInfo && <Account onClose={() => setShowAccountInfo(false)} />}
        {showChangePassword && <ChangePassword onClose={() => setShowChangePassword(false)} />}
    </div>
}

export default MainHeader;