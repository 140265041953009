import { serverFetch } from "@src/server";
import { Button, Form, Input, Modal, Space, message } from "antd";
import React, { useState } from "react";

const ChangePassword = ({...props}) => {
    const { onClose } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState({oldPassword: '', newPassword: '', confirmPassword: ''});


    const onValuesChange = (value) => setEntity({ ...entity, ...value });

    const onFinish = () => {
        setLoading(true);
        serverFetch('auth/ChangePassword', {method: 'POST', bodyData: entity})
            .then(() => {
                message.success("Пароль изменен");
                onClose();
            })
            .catch(() => {
                message.error('Ошибка смены пароля');
                setLoading(false);
            })
        }

        const canBeSaved = entity.newPassword && entity.newPassword === entity.confirmPassword;

    return <Modal open={true}
            forceRender
            title="Смена пароля"
            closable={false}
            closeOnOutsideClick={false}
            footer={null}
            width={540}
            onCancel={() => onClose()}
            bodyStyle={{ margin: '-4px 16px 0' }}
        >
            <Form
                colon={false}
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Form.Item label='Старый пароль' name='oldPassword'><Input.Password disabled={loading}/></Form.Item>
                <Form.Item label='Новый пароль' name='newPassword'><Input.Password disabled={loading}/></Form.Item>
                <Form.Item label='Подтвердить пароль' name='confirmPassword'><Input.Password disabled={loading}/></Form.Item>
                <Form.Item label=" ">
                    <Space>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={loading}>Назад</Button>
                        <Button className='auth-btn' size='middle' loading={loading} htmlType='submit' disabled={!canBeSaved}>Сохранить</Button>
                    </Space>
                </Form.Item>
            </Form>

        </Modal>
}
export default ChangePassword;