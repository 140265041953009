import React, { useState, useEffect } from 'react';
import { Form, Button, Select, Space, DatePicker, Input, Skeleton, SelectProps, Spin } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { GuestsControl, Spinner } from '@controls/index';
import { serverFetch } from '@src/server';
import { IReservationRoomFilter, ICompany, ISearchRoomFilter } from '@entities/index';
import { useAppDispatch } from '@store/hooks';
import { categoryFilterChanged } from '@src/store/actions';
import './search-companies-filter.css';
import { useWindowWidth } from '@react-hook/window-size';
import { useParams } from 'react-router-dom';
import { fmtDateShort, setMetas, withoutTime, withoutTimeZone } from '@extensions/utils';
import LocalFuncs from '@extensions/local-funcs';
import { useSelector } from 'react-redux';
import Maps from '../maps/maps';
import { useNavigate } from 'react-router-dom';

const SearchRoomsFilter = ({ ...props }: ISearchRoomFilter) => {
    const { onSearch, loading, collapsed, setFilterCollapsed, isReady, clearResults } = props;
    const { companyId } = useParams();
    const windowWidth = useWindowWidth();
    const [company, setCompany] = useState<ICompany>();
    const [form] = Form.useForm();
    const [openPopover, setOpenPopover] = useState(false);
    const [checkPricePeriodResult, setCheckPricePeriodResult] = useState<string>('');
    const [filter, setFilter] = useState<IReservationRoomFilter>(LocalFuncs.checkFilterPeriod(useSelector((s:any) => s.reservationRoomFilter)));
    const [showMap, setShowMap] = useState(false);
    const navigate = useNavigate();
    const d = useAppDispatch();

    // Проверка параметров URL для подстановки в фильтр
    const checkSearchParams = (filter_:IReservationRoomFilter) => {
        //console.log("Проверка параметров URL для подстановки в фильтр");
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("setParams")) {
            //console.log("Замена параметров", urlParams.toString())
            const newFilter:IReservationRoomFilter = {...filter_};
            const url = new URL(window.location.href);
            url.searchParams.delete("setParams");
            if (urlParams.get("arrivalDate")){
                //console.log("arrivalDate");
                newFilter.arrivalDate = dayjs(urlParams.get("arrivalDate"));
                url.searchParams.delete("arrivalDate");
            }
            if (urlParams.get("departureDate")){
                //console.log("departureDate");
                newFilter.departureDate = dayjs(urlParams.get("departureDate"));
                url.searchParams.delete("departureDate");
            }
            if (urlParams.get("adultsQty")){
                //console.log("adultsQty");
                newFilter.adultsQty = Number(urlParams.get("adultsQty"));
                url.searchParams.delete("adultsQty");
            }
            if (urlParams.get("childAges")){
                const childAges = urlParams.get("childAges")?.split(",").map(Number);
                //console.log(childAges);
                newFilter.childAges = childAges || [];
                newFilter.childsQty = childAges?.length ?? 0; 
                url.searchParams.delete("childAges");
            }
            //console.log(newFilter);
            const filter2 = LocalFuncs.checkFilterPeriod(newFilter);
            //console.log("Изменено", filter2);
            setFilter(filter2);
            getTariffs(filter2);
            onSearch(filter2);
            d(categoryFilterChanged(filter2));            
            navigate(url, { replace: true });
            //window.location.reload();
        }
    }

    //useEffect(checkSearchParams, []);
    //console.log(filter);
    useEffect(() => {
        serverFetch(`companies/${companyId}`, { method: 'GET' })
            .then((data:ICompany) => {
                setCompany(data);
                // Проверяем корректно выставленного по умолчанию периода
                const filter_ = {...filter};
                if (data.orderTimes && data.orderTimes.length > 0) {
                    if (!data.orderTimes?.find(i => i.orderTimeId === filter.orderTimeId)) {
                        //console.log("Установка фильтра");
                        filter_.orderTimeId = data.orderTimes[0].orderTimeId;
                        setFilter(filter_);
                    }
                }
                checkSearchParams(filter_);
            })
            .catch(e => console.log(`Ошибка получения компании ${e.userMessage || ''} `));
    }, []);

    useEffect(() => setMetas(company), [company]);
    // Получить тарифы по фильтру
    const getTariffs = (data: IReservationRoomFilter) => 
        serverFetch(`booking/CheckPricePeriod`, { method: 'GET', queryParams: { companyId, begPer: withoutTimeZone(data.arrivalDate), endPer: withoutTimeZone(data.departureDate), userType: 1, },})
            .then(() => setCheckPricePeriodResult(''))
            .catch(e => setCheckPricePeriodResult(e.UserMessage));
    // Получить необходимость добавления суток если тариф Полный день
    const getExtraDay = () => {
        if (!filter || filter.orderTimeId === 0 || !company) return 0;
        const orderTime = company.orderTimes?.find((d) => d.orderTimeId === filter.orderTimeId);
        return (orderTime && orderTime.isFullDay) ? 1 : 0;
    };
    // Получить недоступные даты для выбора
    const disabledDate: RangePickerProps['disabledDate'] = (current) => current && dayjs(current) < withoutTime(dayjs(company?.startBookingDate));
    const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => current && (dayjs(current) < withoutTime(dayjs()) || current < filter.arrivalDate)
    const isMobile = windowWidth <= 720; // Переменная Мобильная версия
    const isTablet = windowWidth > 720 && windowWidth <= 1130;
    const isDesktop = windowWidth > 1130; // Десктоп
    const dateFormat = "DD.MM.YYYY";//isMobile ? "DD.MM.YY" : "DD.MM.YYYY";
    const renderFilter = () => {
        if (!isReady) return <Spin />;
        // Промокод - если компания поддерживает промокоды
        const divPromocode = company && company.usePromocode && <Input
                key='promocode'
                placeholder='Промокод'
                className='search-categoies-filter-promo'
                value={filter.promoCode}
                onChange={e => setFilter({...filter, promoCode: e.target.value})}
            />
        // Возможное время заезда
        const timeArrivalOptions: SelectProps['options'] = [];
        //console.log(company?.orderTimes);
        company && company.orderTimes && company.orderTimes.map((d) => 
            timeArrivalOptions.push({ label: d.arrivalTime.toString().substring(0,5) + ' - ' + d.departureTime.toString().substring(0,5), value: d.orderTimeId, key: d.orderTimeId }));        
        const divTimes = company && company.orderTimes && company.orderTimes.length > 0 && <Select
                key='timeArrival'
                options={timeArrivalOptions}
                value={filter.orderTimeId}
                disabled={company.orderTimes.length <= 1}
                style={{width: 130}}
                onChange={e => {
                    setFilter( {...filter, orderTimeId: e });
                    clearResults();
                }}
            />

        //console.log(company);
        const divTimesCaption = company && company.orderTimes && company.orderTimes.length > 0 && <div className='date-caption' key='divTimesCaption'>Время</div>
        // Последнее бронирование
        const dd = company?.lastOrder?.orderDateTime + '+03:00';
        const lastOrder = company && company.lastOrder ? dayjs().diff(dayjs(dd), 'hours') : 0;
        const divLastBook = companyId && lastOrder < 24 && <div className='search-categoies-filter-users' key="div-last-book">
            <img src='/images/bookers.svg' alt='last'/>
            {lastOrder < 24 &&<div>Последний раз бронировали {lastOrder === 0 ? 'несколько минут назад' : `${lastOrder} часов назад`}</div>}
        </div>
        // Просматривают страницу сейчас
        const qtyLook = company ? company.activeUserCount : 0;
        const divLookers = qtyLook > 0 && <div className='search-categoies-filter-users' key="div-lookers">
                <img src='/images/viewers.svg' alt='viewers'/>
                <div>
                    Сейчас просматривают {qtyLook}{' '}
                    {(qtyLook < 10 || qtyLook > 20) && qtyLook % 10 > 1 && qtyLook % 10 < 5 ? 'человека' : 'человек'}{' '}
                </div>
            </div>
        // Адрес
        const divAddress = <div className='search-categories-filter-address' key="div-address">{company?.address}</div>;
        // #region Даты заезда выезда
        const divDateArrivalCaption = <div className='date-caption' key='divDateArrivalCaption'>Заезд</div>
        const divDateArrival = company ? <DatePicker
                key='arrivalDate'
                className='date-picker'
                placeholder='Дата заезда'
                disabledDate={disabledDate}
                value={filter.arrivalDate}
                allowClear={false}
                format={dateFormat}
                inputReadOnly={true}
                onChange={(_, value) => {
                    console.log(value);
                    const days = filter.departureDate.diff(filter.arrivalDate, 'day');
                    const arrivalDate = dayjs(value, 'DD-MM-YYYY');
                    const departureDate = arrivalDate.add(days, 'day');
                    const newFilter = { ...filter, arrivalDate, departureDate };
                    setFilter(newFilter);
                    getTariffs(newFilter);
                }}
            /> : <Skeleton.Input key='arrivalDate' />
        const divDateDepartureCaption = <div className='date-caption' key='divDateDepartureCaption'>Выезд</div>
        const divDateDeparture = company ? <DatePicker
                key='departureDate'
                className='date-picker'
                placeholder='Дата выезда'
                disabledDate={disabledDateEnd}
                value={filter.departureDate}
                allowClear={false}
                format={dateFormat}
                inputReadOnly={true}
                onChange={(_, value) => {
                    //console.log(value);
                    const newFilter = { ...filter, departureDate: dayjs(value, 'DD-MM-YYYY') };
                    setFilter(newFilter);
                    getTariffs(newFilter);
                }}
            /> : <Skeleton.Input key='departureDate' />
        // #endregion
        // #region Выбор гостей
        const divGuests = (
            <GuestsControl
                key='guests'
                className='search-category-filter-guests'
                filter={filter}
                form={form}
                setFilter={setFilter}
                openPopover={openPopover}
                handleOpenPopoverChange={setOpenPopover}
            />
        );
        // #endregion
        // #region Срок
        const srok = filter.departureDate.diff(filter.arrivalDate, 'day') + getExtraDay();
        // Проверить условие по минимальному сроку
        // Проверить попадает ли период проживания localFilter.arrivalDate, localFilter.departureDate в полные периоды tarifss
        const srokErrorMsg = (company && srok < company.minOrderDaysForTourist) ? `(Минимальный срок ${company.minOrderDaysForTourist} суток)` : checkPricePeriodResult
        const srokError = company && srokErrorMsg !== '';
        const divSrokError = srokErrorMsg && <div className={srokErrorMsg ? 'srok-error' : ''} key='divSrok'>{srokErrorMsg}</div>
        const divSrok = <div className={srokErrorMsg ? 'srok-error' : ''} key='divSrok'><Space><div className='date-caption'>Срок</div>{`${srok} суток`}</Space></div>
        // #endregion
        const divBtnSearch = <div key='divBtnSearch' className='search-categories-filter-btn-search'>
                {loading ? <Spinner /> : <Button
                        onClick={() => { d(categoryFilterChanged(filter)); onSearch(filter); }}
                        className='search-btn'
                        type='primary'
                        htmlType='submit'
                        disabled={srokError}
                    >Найти</Button>}
            </div>
        const divCompany = companyId && (company ? <div className='search-companies-filter-title' onClick={clearResults}>
            <div className='search-companies-filter-company'>{company && company.companyName}</div>
            <Button onClick={() => setShowMap(true)}>{isMobile ? "Карта" : "Показать на карте"}</Button>
            </div> : (<Skeleton.Input />));
        const divCollapsedInfo = isMobile ? <Space direction='vertical' className='search-categories-filter-collapsed'  key="space-v8">
                <div>{company?.companyName}</div>
                <div><img src='/images/calendar.svg' alt='calendar'/> {fmtDateShort(filter.arrivalDate)} - {fmtDateShort(filter.departureDate)}</div>
                <div><img src='/images/man.svg' alt='guests'/> {filter.adultsQty} взрослых {filter.childsQty > 0 && <> - {filter.childsQty} детей</>}</div>
                <div>{`${srok} суток`}</div>
                <Button onClick={() => setFilterCollapsed(false)} className='search-btn'>Изменить</Button>
            </Space> 
            : isTablet ? <>
            <Space className='search-categories-filter-collapsed'>
            <div>{company?.companyName}</div>
                <div><img src='/images/calendar.svg' alt='period'/> {fmtDateShort(filter.arrivalDate)} - {fmtDateShort(filter.departureDate)}</div>
                <div><img src='/images/man.svg' alt='guests' /> {filter.adultsQty} взрослых {filter.childsQty > 0 && <> - {filter.childsQty} детей</>}</div>
                <div>{`${srok} суток`}</div>
            </Space>
            <Space className='search-categories-filter-collapsed'>
                <Button onClick={() => setFilterCollapsed(false)} className='search-btn'>Изменить</Button>
            </Space>
            </>
            :<Space className='search-categories-filter-collapsed'  key="space-v7">
                <div>{company?.companyName}</div>
                <div><img src='/images/calendar.svg' alt='calendar'/> {fmtDateShort(filter.arrivalDate)} - {fmtDateShort(filter.departureDate)}</div>
                <div><img src='/images/man.svg' alt='guests'/> {filter.adultsQty} взрослых {filter.childsQty > 0 && <> - {filter.childsQty} детей</>}</div>
                <div>{`${srok} суток`}</div>
                <Button onClick={() => setFilterCollapsed(false)} className='search-btn'>Изменить</Button>
            </Space>
        const divMaps = showMap && <Maps onClose={() => setShowMap(false)}
            coords={[{name: company?.companyName, x: parseFloat(company?.gpsLatitude ?? ''), y: parseFloat(company?.gpsLongitud ?? '') }]}
            onBook={() => setShowMap(false)} />
        return <>
            {collapsed ? divCollapsedInfo : <div>
                <div className='search-companies-filter-container' key="search-companies-filter-container">
                    <div className='search-companies-filter' key="search-companies-filter">
                        {divCompany}
                        {isDesktop && <>
                            <Space>{[divDateArrivalCaption,divDateArrival,divDateDepartureCaption,divDateDeparture,divTimesCaption,divTimes,divSrok,]}</Space>
                            {divSrokError}</>}
                        {isTablet && <>
                            <Space>{[divDateArrivalCaption,divDateArrival,divDateDepartureCaption,divDateDeparture]}</Space>
                            <Space>{[divTimesCaption,divTimes,divSrok]}</Space>
                        </> }
                        {isMobile && <>
                            <Space>{[divDateArrivalCaption, divDateArrival]}</Space>
                            <Space>{[divDateDepartureCaption, divDateDeparture]}</Space>
                            <Space>{[divTimesCaption, divTimes]}</Space>
                            {divSrok}
                            {divSrokError}</>}
                        {!isMobile && <Space key="space-v6">{[divGuests, divPromocode, divBtnSearch]}</Space>}
                        { isMobile && [divGuests, divPromocode, divBtnSearch]}
                        { isMobile && [divAddress, divLastBook, divLookers]}
                        {!isMobile && <Space key="space-v5">{divAddress}{divLastBook}{divLookers}</Space>}

                    </div>
                    {company && <div className='search-companies-filter-images'>
                        {(company.proffitSystem || company.useV2) && <img alt='instant confirm'
                            src={isMobile ? '/images/mgnovennoe_podtverzhdenie_m.png' : '/images/mgnovennoe_podtverzhdenie.png'}
                            className='search-companies-filter-image'/>}
                        {!company.proffitSystem && !company.useV2 && <img alt='proffit' src={isMobile ? '/images/garantir_podtverzhdenie_m.png' : '/images/garantir_podtverzhdenie_144.png'} className='search-companies-filter-image' />}
                        {company.usePromocode && <img alt='promo' src={isMobile ? '/images/promocode_red_m.png' : '/images/promocode_red.png'} className='search-companies-filter-image' />}
                        {company.isRusTourism && <img alt='discount' src={isMobile ? '/images/online_skidka_red_m.png' : '/images/online_skidka_red.png'} className='search-companies-filter-image' />}
                        {!isMobile && <div className='search-categoies-filter-systema'><a href='https://fitnessoft.ru/portal' target='_blank' rel='noreferrer'>Система онлайн бронирования</a></div>}
                    </div>}
                </div>
                <div className='search-categories-filter-section-center'>
                {isDesktop ? <img src="/images/BookingOptions3.png" alt='booking'/> : "НАДЕЖНОЕ И ВЫГОДНОЕ БРОНИРОВАНИЕ"}
                </div>                
            </div>}
            {divMaps}
        </>
    };
    //console.log(filter);
    return <>{filter && renderFilter()}</>;
};

export default SearchRoomsFilter;